const _cmd = [];

const PRIORITY = {
  high: 100,
  medium: 50,
  low: 0,
};

const cmd = () => _cmd.sort((a, b) => b.priority - a.priority);

const lazy = (func, priority = PRIORITY.medium) => {
  if (typeof func !== 'function') {
    throw new Error('"func" is not a function.');
  }

  if (vokkaAds.isLoaded) {
    // すでに遅延読み込み済みなので、即時実行
    func();
  }

  _cmd.push({ func, priority });
};

const lazyInsertScriptTag = (url, priority = PRIORITY.medium, className = null) => {
  if (typeof url !== 'string') {
    throw new Error('"url" is not a string.');
  }

  lazy(() => {
    const lazyTarget = document.createElement('script');
    lazyTarget.type = 'text/javascript';
    lazyTarget.async = true;
    lazyTarget.src = url;
    if (className) { lazyTarget.classList.add(className); }

    const script = document.getElementsByTagName('script')[0];
    script.parentNode.insertBefore(lazyTarget, script);
  }, priority);
};

export const vokkaAds = {
  priority: PRIORITY,
  cmd,
  lazy,
  lazyInsertScriptTag,
  isLoaded: false,
};

(function () {
  vokkaAds.isLoaded = false;

  const lazyLoad = () => {
    if (vokkaAds.isLoaded) {
      return;
    }

    vokkaAds.isLoaded = true;

    window.removeEventListener('keydown', lazyLoad);
    window.removeEventListener('mousedown', lazyLoad);
    window.removeEventListener('mousemove', lazyLoad);
    window.removeEventListener('scroll', lazyLoad);
    window.removeEventListener('touchstart', lazyLoad);

    vokkaAds.cmd().forEach(({ func }) => func());
  };

  setTimeout(() => {
    window.addEventListener('keydown', lazyLoad);
    window.addEventListener('load', () => {
      if (window.scrollY > 0) {
        lazyLoad();
      } else {
        setTimeout(lazyLoad, 2500);
      }
    });
    window.addEventListener('mousedown', lazyLoad);
    window.addEventListener('mousemove', lazyLoad);
    window.addEventListener('scroll', lazyLoad);
    window.addEventListener('touchstart', lazyLoad);
  });
})();
